<template>
  <b-card title="On Boarded Affiliate List">

    <!-- search input -->
    <div class="custom-search d-flex justify-content-end">
      <b-form-group class="justify-content-end">
        <div class="d-flex align-items-center">
          <label class="mr-1">Search</label>
          <b-form-input
              v-model="searchTerm"
              placeholder="Search"
              type="text"
              class="d-inline-block"
          />
        </div>
      </b-form-group>
    </div>

    <!-- table -->
    <vue-good-table
        :columns="columns"
        :rows="rows"
        :rtl="direction"
        :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
        :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
        styleClass="vgt-table striped condensed no-outline"
    >

      <template
          slot="table-row"
          slot-scope="props"
      >

        <!-- Column: Name -->
        <span
            v-if="props.column.field === 'status'"
            class="text-nowrap"
        >
          <span   style="cursor:pointer">
<!--                @click="updateFeaturedStatus(props.row.id)"-->

              <span v-if="props.row.user.is_active==true"><b-badge pill variant="light-success">Active</b-badge></span>
              <span v-else> <b-badge pill variant="light-danger"> Inactive</b-badge></span>
            </span>
        </span>
        <span v-else-if="props.column.field === 'logo'">
          <b-avatar thumbnail fluid :src="props.row.logo" alt="Image 1"/>
        </span>
        <span v-else-if="props.column.field === 'created_at'">
         <span>{{ props.row.created_at.split('T')[0] }}</span>
        </span>
        <span v-else-if="props.column.field === 'index'">
         <span>{{ props.row.originalIndex + 1 }}</span>
        </span>
        <span v-else-if="props.column.field === 'user_name'">
         <span>{{ props.row.user.first_name+" "+props.row.user.last_name }}</span>
          <br>
         <small class="font-weight-bolder">{{ props.row.user.email }}</small>
        </span>
        <span v-else-if="props.column.field === 'organization_name'">
         <span>{{ props.row.organization_name }}</span>
          <br>
         <small class="font-weight-bolder">{{ props.row.organization_address }}</small>
        </span>





        <!-- Column: Status -->

        <!-- Column: Action -->

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
          slot="pagination-bottom"
          slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Showing 1 to
            </span>
            <b-form-select
                v-model="pageLength"
                :options="['3','5','10']"
                class="mx-1"
                @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>


  </b-card>

</template>

<script>

import {

  BFormFile,
  BCol,
  BFormTextarea,
  BForm,
  BFormRating,
  BFormInvalidFeedback,
  BInputGroup,
  BInputGroupPrepend,
  BFormValidFeedback,
  BImg, BContainer,
  BAvatar,
  BButton,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BCard,
  BModal,
  VBModal,
  BCardText
} from 'bootstrap-vue'
import {VueGoodTable} from 'vue-good-table'
import store from '@/store'
import vSelect from "vue-select"
import Ripple from "vue-ripple-directive";
import {mapState} from "vuex";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";


export default {
  components: {
    VueGoodTable,
    BAvatar,
    BCard,
    BBadge,
    BPagination,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BModal,
    BCardText,
    BFormFile,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BForm,
    BButton,
    BFormRating,
    BFormInvalidFeedback,
    BInputGroup,
    BInputGroupPrepend,
    BFormValidFeedback,
    BImg, BContainer,
    vSelect
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  computed: {

    ...mapState('affiliate', ["affiliates", "submitError", "submitLoading"]),

    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  watch: {

    affiliates(newValue, oldValue) {
      if (newValue) {
        this.rows = this.affiliates
      }
    }
  },
  data() {
    return {
      nameState: null,
      addNewMode: false,
      popupActive: true,
      modify: {
        "name": null,
        "charge": null,
      },
      pageLength: 5,
      dir: false,
      columns: [

        {
          label: '#',
          field: 'index',
          type: 'number',
          tdClass: 'text-center',
          thClass: 'text-center'
        },
        {
          label: 'Name',
          field: 'user_name',
          tdClass: 'text-center',
          thClass: 'text-center'
        },
        {
          label: 'Type',
          field: 'affiliate_type',
          tdClass: 'text-center',
          thClass: 'text-center'
        },
        {
          label: 'Organization',
          field: 'organization_name',
          tdClass: 'text-center',
          thClass: 'text-center'
        },
        {
          label: 'OnBoarding Commission',
          field: 'onboarding_commission',
          tdClass: 'text-center',
          thClass: 'text-center'
        },

        {
          label: 'Retention Commission',
          field: 'retention_commission',
          tdClass: 'text-center',
          thClass: 'text-center',
          sortable: false,
        },
        {
          label: 'Status',
          field: 'status',
          tdClass: 'text-center',
          thClass: 'text-center',
          sortable: false,
        },



      ],
      methodId: null,
      showAdd: true,
      showEdit: true,
      showDelete: true,
      rows: [],
      searchTerm: '',
      product_image1: null,
      product_image_id1: undefined,
    }
  },
  beforeCreate() {

  },
  created() {
    this.$store.dispatch('affiliate/fetchAffiliateList').then(result => {
      this.rows = this.businesses

    })
  },
  methods: {

    updateStatus(data) {
      this.$store.dispatch('business/toggleBusinessStatus', {id: data}).then(result => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: result.code === 400 ? `Error` : `Success`,
            icon: result.code === 400 ? `ThumbsDownIcon` : 'ThumbsUpIcon',
            variant: result.code === 400 ? `danger` : 'success',
            text: result.message,
          },
        })
      })
    },
    updateCommissionStatus(data) {
      this.$store.dispatch('business/toggleBusinessCommissionStatus', {id: data}).then(result => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: result.code === 400 ? `Error` : `Success`,
            icon: result.code === 400 ? `ThumbsDownIcon` : 'ThumbsUpIcon',
            variant: result.code === 400 ? `danger` : 'success',
            text: result.message,
          },
        })
      })
    },
    updateFeaturedStatus(data) {
      this.$store.dispatch('business/toggleBusinessFeatureStatus', {id: data}).then(result => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: result.code === 400 ? `Error` : `Success`,
            icon: result.code === 400 ? `ThumbsDownIcon` : 'ThumbsUpIcon',
            variant: result.code === 400 ? `danger` : 'success',
            text: result.message,
          },
        })
      })
    },


  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
